<template>
  <div>
    <!-- <div>
      <van-nav-bar title="资讯详情" />
    </div> -->
    <!-- 内容 -->
    <div class="essay">
      <div class="essayTitle">{{ list.Title }}</div>
      <div class="rikind">{{ list.AKName }}</div>
      <div v-if="list.AImgUrl" class="essayImg">
        <img :src="list.AImgUrl" alt="" />
      </div>
      <div class="essayContent" v-html="list.Content"></div>
      <div class="video" v-if="list.Annex">
        <video style="width: 100%" :src="list.Annex" controls="controls">
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <van-button class="button" v-if="list.IsRecommend == 1" @click="Activity" round type="info"
        >活动申请</van-button
      >
      <van-button class="button" @click="SignActive" round type="info"
        >签 到</van-button
      >
    </div>
  </div>
</template>
<script>
import { WeGetActivityDetail, WeEnrolActivity } from "@/api/PBActivity";
import {WxSignActive} from "@/api/yeguang"
import { setOpenId, getOpenId } from "@/utils/auth";
import { Dialog } from 'vant';
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      list: {}, //活动详情
      aids: 0, //活动aid
      ActivityFrom: {}, //活动申请提交参数
    };
  },
  created() {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
  },
  methods: {
    // 获取活动详情
    getRealInfo() {
      // getOpenId()
      this.aids = this.$route.params.Id;
      WeGetActivityDetail({ openID: getOpenId(), aId: this.aids }).then((res) => {
        this.list = res.data.data;
      });
    },
    SignActive(){
      WxSignActive({Id: this.aids,Group:2,OpenID:getOpenId()}).then((res)=>{
        if(res.data.code == 0){
          Dialog.alert({
            message: "打卡成功!",
          }).then(() => {
            // on close
          });
        }else{
          Dialog.alert({
            message: "打卡失败," + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      })
    },
    Activity() {
      this.ActivityFrom.AId = this.aids;
      this.ActivityFrom.OpenID = getOpenId();
      WeEnrolActivity(this.ActivityFrom).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Dialog.alert({
            message: "提交成功!",
          }).then(() => {
            // on close
          });
        } else {
          Dialog.alert({
            message: "申请失败," + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
  },
  mounted() {
    this.getRealInfo();
  },
};
</script>
<style>
.button {
  width: 95%;
  margin: 15px 2.5%;
}
</style>